import { Box, Button, Typography, useMediaQuery } from '@mui/material';

function Hero({ mailToSupport }: { mailToSupport: () => void }) {
    const isMobile = useMediaQuery('(max-width:800px)');
    const isLess1100 = useMediaQuery('(max-width:1100px)');

    return (
        <Box
            id="header_anchor"
            sx={{
                background: '#F8F9FA',
                backgroundImage: isMobile ? undefined : 'url(/images/background_image.webp)',
                backgroundSize: '1800px 906px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: '106px 20px 0',
                    display: 'flex',
                    alignItems: isMobile ? 'center' : 'flex-start',
                    flexDirection: 'column',
                }}
            >
                {isMobile && <img width={189} src="/images/hero_image.webp" alt="hero"></img>}
                <Box
                    sx={{
                        maxWidth: isLess1100 ? '750px' : '900px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: isMobile ? '20px' : '30px',
                        margin: isMobile ? '40px 0 80px' : '164px 0 120px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '32px' : '56px',
                            fontFamily: 'Inter500',
                            lineHeight: 'normal',
                            span: {
                                background: 'linear-gradient(90deg, #FFB778 0%, #FF78AE 27.92%, #9772FF 55.84%)',
                                backgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            },
                        }}
                    >
                        <span>Apphitminchan Ltd.</span> is a dynamic and innovative team of specialists in mobile
                        application development and publishing.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '20px',
                            fontFamily: 'Inter400',
                            lineHeight: '30px',
                            color: 'rgba(25, 25, 25, 0.70)',
                            maxWidth: isLess1100 ? '550px' : '660px',
                        }}
                    >
                        Established in 2021, our company was founded by software professionals and entrepreneurs with
                        over 15 years of collective experience spanning mobile app development, marketing, consulting,
                        and IT project management.
                    </Typography>
                    <Button
                        onClick={() => {
                            mailToSupport();
                        }}
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#191919',
                            borderRadius: '32px',
                            height: '64px',
                            width: 'max-content',
                            padding: '14px 40px',
                            color: '#FFF',
                            textTransform: 'initial',
                            fontSize: '20px',
                            fontFamily: 'Inter500',
                            lineHeight: 'normal',
                            '&:hover': {
                                background: isMobile ? undefined : 'rgba(25, 25, 25, 0.70)',
                            },
                        }}
                    >
                        Contact us
                        <img src="/images/btn_arrow.svg" alt="arrow"></img>
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Hero;
