import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { APP_URLS } from '../App.constants';

function CloudBasedSolution({ setVideoOpened }: { setVideoOpened: (s: boolean) => void }) {
    const isMobile = useMediaQuery('(max-width:800px)');

    return (
        <Box
            sx={{
                background: '#191919',
            }}
        >
            <Box
                id="cb_solution"
                sx={{
                    scrollMarginTop: isMobile ? '30px' : undefined,
                    maxWidth: '1350px',
                    padding: isMobile ? '80px 20px' : '120px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '80px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: isMobile ? '32px' : '56px',
                            fontFamily: 'Inter500',
                            lineHeight: 'normal',
                            color: '#fff',
                        }}
                    >
                        Innovative Product:
                        <br />
                        Cloud-Based Solution
                    </Typography>
                    {!isMobile && <img width={83} src="/images/image_247.webp" alt="decor"></img>}
                </Box>
                <Box
                    sx={{
                        borderRadius: '32px',
                        overflow: 'hidden',
                        background: '#252525',
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        sx={{
                            padding: isMobile ? '20px 20px' : '50px 40px 40px 40px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <img width={75} src="/images/growstats_icon.svg" alt="decor"></img>
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '20px',
                                }}
                            >
                                <Button
                                    onClick={() => {
                                        setVideoOpened(true);
                                    }}
                                    sx={{
                                        display: 'flex',
                                        gap: '20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: '#FFF',
                                        borderRadius: '32px',
                                        height: '64px',
                                        width: 'max-content',
                                        padding: isMobile ? '4px' : '14px 40px',
                                        color: '#191919',
                                        textTransform: 'initial',
                                        fontSize: '20px',
                                        fontFamily: 'Inter500',
                                        lineHeight: 'normal',
                                        '&:hover': {
                                            background: isMobile ? undefined : 'rgba(255, 255, 255, 0.70)',
                                        },
                                    }}
                                >
                                    <img src="/images/play.svg" alt="arrow"></img>
                                    {!isMobile && 'See the Platform in Action'}
                                </Button>
                                <Box
                                    onClick={() => {
                                        window.open(APP_URLS.GROWSTATS, '_blank');
                                    }}
                                    sx={{
                                        borderRadius: '50%',
                                        border: '1px solid #3B3B3B',
                                        width: '64px',
                                        height: '64px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            border: isMobile ? undefined : '1px solid rgba(255, 255, 255, 0.30)',
                                        },
                                    }}
                                >
                                    <img src="/images/arrow_top.svg" alt="decor"></img>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '40px',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '24px' : '40px',
                                        fontFamily: 'Inter500',
                                        lineHeight: 'normal',
                                        color: '#fff',
                                    }}
                                >
                                    GrowStats.io
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: isMobile ? 'column' : 'row',
                                        gap: isMobile ? 0 : '50px',
                                        '.text': {
                                            fontSize: '16px',
                                            fontFamily: 'Inter400',
                                            lineHeight: '24px',
                                            color: 'rgba(255, 255, 255, 0.70)',
                                            width: '100%',
                                            a: {
                                                color: '#fff',
                                            },
                                        },
                                    }}
                                >
                                    <Typography className="text">
                                        A comprehensive, cloud-based Software-as-a-Service (SaaS) dashboard platform
                                        designed for data-driven decision-making. Explore{' '}
                                        <a href={APP_URLS.GROWSTATS} target="_blank" rel="noreferrer">
                                            GrowStats.io
                                        </a>
                                        .With our extensive experience in web and mobile app development and publishing,
                                        Apphitminchan Ltd has successfully applied these insights to create and scale
                                        our own SaaS platform.
                                    </Typography>
                                    <Typography className="text">
                                        This platform is designed to help businesses efficiently measure and track the
                                        performance of their software products and mobile apps.By leveraging our deep
                                        understanding of development processes, we offer a powerful tool that provides
                                        actionable analytics, enabling businesses to make informed decisions, optimize
                                        their apps, and drive growth.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                height: '1px',
                                background: 'rgba(255, 255, 255, 0.15)',
                                margin: '40px 0',
                            }}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontFamily: 'Inter500',
                                    lineHeight: 'normal',
                                    color: '#fff',
                                }}
                            >
                                Features include:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobile ? 'column' : 'row',
                                    gap: isMobile ? 0 : '50px',
                                    '.wrapper': {
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                        '.feature': {
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            '.feature_text': {
                                                fontSize: '16px',
                                                fontFamily: 'Inter400',
                                                lineHeight: '24px',
                                                color: 'rgba(255, 255, 255, 0.70)',
                                            },
                                        },
                                    },
                                }}
                            >
                                <Box className="wrapper">
                                    <Box className="feature">
                                        <img src="/images/check.svg" alt="check" />
                                        <Typography className="feature_text">
                                            Advanced analytics for user behavior and cohort analysis
                                        </Typography>
                                    </Box>
                                    <Box className="feature">
                                        <img src="/images/check.svg" alt="check" />
                                        <Typography className="feature_text">
                                            Seamless integration with advertising networks
                                        </Typography>
                                    </Box>
                                    <Box className="feature">
                                        <img src="/images/check.svg" alt="check" />
                                        <Typography className="feature_text">
                                            AI-generated insights to optimize business strategies
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    className="wrapper"
                                    sx={{
                                        marginTop: isMobile ? '10px' : undefined,
                                    }}
                                >
                                    <Box className="feature">
                                        <img src="/images/check.svg" alt="check" />
                                        <Typography className="feature_text">
                                            Robust APIs, tools, and documentation for managing data effectively
                                        </Typography>
                                    </Box>
                                    <Box className="feature">
                                        <img src="/images/check.svg" alt="check" />
                                        <Typography className="feature_text">AI-powered LTV predictions</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <img width={'100%'} src="/images/growstats_io_image.webp" alt="decor"></img>
                </Box>
            </Box>
        </Box>
    );
}

export default CloudBasedSolution;
