import { Box, Button, Link, Typography, useMediaQuery } from '@mui/material';
import { APP_URLS } from '../App.constants';

function Footer({ mailToSupport, menuFooter }: { mailToSupport: () => void; menuFooter?: boolean }) {
    const isMobile = useMediaQuery('(max-width:800px)');

    return (
        <Box
            id="footer_anchor"
            sx={{
                background: '#191919',
                backgroundPosition: 'center',
                scrollMarginTop: isMobile ? '50px' : '100px',
            }}
        >
            <Box
                sx={{
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: menuFooter ? '0 20px' : isMobile ? '80px 20px 0px' : '80px 20px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: isMobile ? '0' : '80px',
                    borderTop: menuFooter ? undefined : '1px solid #2E2E2E',
                }}
            >
                {!menuFooter && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isMobile ? '40px' : '60px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: isMobile ? '20px' : '50px',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontFamily: 'Inter500',
                                    color: '#fff',
                                    lineHeight: 'normal',
                                    maxWidth: '977px',
                                    textAlign: isMobile ? 'center' : undefined,
                                }}
                            >
                                We’d love to hear from you! Whether you’re looking to collaborate, learn more about our
                                products, or join our team, reach out to us directly.
                            </Typography>
                            <Button
                                onClick={() => {
                                    mailToSupport();
                                }}
                                sx={{
                                    display: 'flex',
                                    gap: '20px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    background: '#fff',
                                    borderRadius: '32px',
                                    height: '64px',
                                    width: isMobile ? '100%' : '174px',
                                    minWidth: '174px',
                                    color: '#191919',
                                    textTransform: 'initial',
                                    fontSize: '20px',
                                    fontFamily: 'Inter500',
                                    lineHeight: 'normal',
                                    textWrap: 'nowrap',
                                    margin: isMobile ? 'auto' : undefined,
                                    '&:hover': {
                                        background: isMobile ? undefined : 'rgba(255, 255, 255, 0.70)',
                                    },
                                }}
                            >
                                Join Us
                                {!isMobile && <img src="/images/btn_arrow_black.svg" alt="arrow"></img>}
                            </Button>
                        </Box>
                        {isMobile && (
                            <Box
                                sx={{
                                    background: 'rgba(255, 255, 255, 0.15)',
                                    height: '1px',
                                    // margin: '40px 0',
                                    width: '100%',
                                }}
                            />
                        )}
                        <Link
                            sx={{
                                fontSize: '24px',
                                fontFamily: 'Inter500',
                                color: isMobile ? '#fff' : 'rgba(255, 255, 255, 0.70)',
                                lineHeight: 'normal',
                                textDecoration: 'none',
                                textAlign: 'left',
                                display: 'flex',
                                gap: '10px',
                                alignItems: 'center',
                                transition: 'all 0.5s ease',
                                margin: isMobile ? 'auto' : undefined,
                                '&:hover': {
                                    color: '#fff',
                                },
                            }}
                            href={`mailto:${APP_URLS.SUPPORT}`}
                        >
                            {!isMobile && <img src="/images/mail.svg" alt="mail"></img>}
                            {APP_URLS.SUPPORT}
                        </Link>
                    </Box>
                )}

                <Box
                    sx={{
                        background: 'rgba(255, 255, 255, 0.15)',
                        height: '1px',
                        margin: isMobile ? '40px 0' : '80px 0',
                        width: '100%',
                    }}
                />
                <Box
                    sx={{
                        position: 'relative',
                        img: {
                            maxWidth: '100%',
                        },
                    }}
                >
                    <img src="/images/footer_logo.svg" alt="logo"></img>
                    <img
                        style={{
                            width: '9%',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                        }}
                        src="/images/footer_image.webp"
                        alt="decor"
                    ></img>
                </Box>
                <Box
                    sx={{
                        margin: isMobile ? '40px 0' : '80px 0',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '20px' : undefined,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .text': {
                            fontSize: '14px',
                            fontFamily: 'Inter400',
                            color: 'rgba(255, 255, 255, 0.70)',
                            lineHeight: 'normal',
                            textAlign: 'center',
                        },
                    }}
                >
                    <Typography className="text">Apphitminchan limited HE427515</Typography>
                    <Typography className="text">
                        Arch. Makariou III, 195 NEOCLEOUS HOUSE 3030 Limassol Cyprus
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '40px',
                        }}
                    >
                        <Typography
                            onClick={() => {
                                window.open(APP_URLS.PRIVACY, '_blank');
                            }}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: isMobile ? undefined : '#fff!important',
                                },
                            }}
                            className="text"
                        >
                            Privacy Policy
                        </Typography>

                        <Typography
                            onClick={() => {
                                window.open(APP_URLS.TERMS, '_blank');
                            }}
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: isMobile ? undefined : '#fff!important',
                                },
                            }}
                            className="text"
                        >
                            Terms of Use
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default Footer;
