import { Box, Button, Typography, useMediaQuery } from '@mui/material';

function JoinUs({ mailToSupport }: { mailToSupport: () => void }) {
    const isMobile = useMediaQuery('(max-width:900px)');

    return (
        <Box
            id="join_us"
            sx={{
                scrollMarginTop: '40px',
            }}
        >
            <Box
                sx={{
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: isMobile ? '80px 20px' : '80px 20px 124px',
                }}
            >
                <Box
                    sx={{
                        background: '#F8F9FA',
                        height: isMobile ? undefined : '184px',
                        overflow: 'hidden',
                        borderRadius: isMobile ? '24px' : '92px',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: isMobile ? '100%' : undefined,
                            borderBottom: isMobile ? '1px solid rgba(25, 25, 25, 0.10)' : undefined,
                        }}
                    >
                        <img height={184} width={215} src="/images/portrait.webp" alt="portrait"></img>
                        {isMobile && (
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Inter500',
                                        lineHeight: 'normal',
                                        maxWidth: '108px',
                                        marginBottom: '30px',
                                        span: {
                                            color: 'rgba(25, 25, 25, 0.40)',
                                            fontFamily: 'Inter400',
                                        },
                                    }}
                                >
                                    Maxim Salahub
                                    <br />
                                    <span>Head of development team</span>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box
                        sx={{
                            width: isMobile ? undefined : '100%',
                            padding: isMobile ? '30px' : '0 20px 0 0',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontFamily: 'Inter500',
                                lineHeight: 'normal',
                            }}
                        >
                            Are you ready to make an impact?
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontFamily: 'Inter400',
                                lineHeight: '24px',
                                color: 'rgba(25, 25, 25, 0.70)',
                                marginTop: '5px',
                            }}
                        >
                            Join our team of innovators and shape the future of mobile and web applications.
                        </Typography>
                        {!isMobile && (
                            <Box
                                sx={{
                                    border: '1px solid rgba(25, 25, 25, 0.10)',
                                    borderRadius: '12px',
                                    padding: '12px 15px',
                                    marginTop: '20px',
                                    width: 'max-content',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        background:
                                            'linear-gradient(225deg, #AC5FFF 14.58%, #FF7DC3 50%, #FFC69A 85.42%)',
                                        width: '16px',
                                        height: '16px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Inter500',
                                        lineHeight: 'normal',
                                        marginLeft: '5px',
                                    }}
                                >
                                    Maxim Salahub
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontFamily: 'Inter500',
                                        lineHeight: 'normal',
                                        color: 'rgba(25, 25, 25, 0.40)',
                                    }}
                                >
                                    , Head of development team
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Button
                        onClick={() => {
                            mailToSupport();
                        }}
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: '#191919',
                            borderRadius: '32px',
                            height: '64px',
                            width: isMobile ? 'calc(100% - 60px)' : '174px',
                            minWidth: '174px',
                            marginRight: '60px',
                            margin: isMobile ? '0 auto 30px' : '0 60px 0 0',
                            color: '#FFF',
                            textTransform: 'initial',
                            fontSize: '20px',
                            fontFamily: 'Inter500',
                            lineHeight: 'normal',
                            textWrap: 'nowrap',
                            '&:hover': {
                                background: isMobile ? undefined : 'rgba(25, 25, 25, 0.70)',
                            },
                        }}
                    >
                        Join Us
                        {!isMobile && <img src="/images/btn_arrow.svg" alt="arrow"></img>}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default JoinUs;
