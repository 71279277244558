import { useEffect } from 'react';
import ModalVideo from 'react-modal-video';

import 'react-modal-video/css/modal-video.css';

function PlayVideo({ isOpened, setOpened }: { isOpened: boolean; setOpened: (s: boolean) => void }) {
  const isSafari = () => {
    const ua = navigator.userAgent;
    return /^((?!chrome|android).)*safari/i.test(ua);
  };

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
      document.body.style.touchAction = 'none';
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.touchAction = 'auto';
    }
  }, [isOpened]);

  return (
    <ModalVideo
      channel="youtube"
      youtube={
        isSafari()
          ? {
              mute: 1,
              autoplay: 1,
            }
          : {
              mute: 0,
              autoplay: 1,
            }
      }
      allowFullScreen
      isOpen={isOpened}
      videoId="d2TLaFv9flI"
      onClose={() => setOpened(false)}
    />
  );
}

export default PlayVideo;
