import { Box, Typography, useMediaQuery } from '@mui/material';

function OurApps() {
    const isMobile = useMediaQuery('(max-width:1000px)');
    const points = [
        {
            img: '/images/icons/icon4.svg',
            title: 'Flexible and Modern Apps',
            text: 'At Apphitminchan Ltd, we specialize in the development of web and mobile applications that offer unique advantages over traditional app store-based solutions. Our web apps provide seamless cross-platform access through browsers, eliminating the need for app store approvals and offering quicker updates.',
        },
        {
            img: '/images/icons/icon5.svg',
            title: 'Scalable and Responsive',
            text: 'This flexibility allows us to deliver highly responsive solutions that are cost-effective and scalable. By leveraging this approach, our clients can easily adapt to user feedback and evolving market demands, ensuring their applications remain relevant and effective.',
        },
        {
            img: '/images/icons/icon6.svg',
            title: 'Engaging User Experiences',
            text: "With a focus on user-friendly interfaces and robust functionality, we create experiences that enhance engagement and foster loyalty. We take pride in showcasing a suite of innovative applications designed to enrich users' lives.",
        },
    ];

    const apps = [
        {
            icon: '/images/apps/icon_1.webp',
            image: '/images/apps/image_1.webp',
            title: 'Atria',
            text: "A cutting-edge astrology app that provides daily horoscopes, compatibility analysis, and personalized astrological insights, complemented by a sleek and intuitive interface. The app attracts users seeking guidance on relationships, career choices, and personal growth, providing astrological charts tailored to individuals' birth details. The app offers instant, accessible wisdom in a format that appeals to modern lifestyles, making astrology more accessible than ever before. The growing interest in spirituality and self-awareness, combined with intuitive and sleek interfaces, makes the app popular among users. ",
        },
        {
            icon: '/images/apps/icon_2.webp',
            image: '/images/apps/image_2.webp',
            title: 'Live Palmistry App',
            text: "Our palmistry app, powered by advanced AI technology, offers users a unique and engaging experience by providing accurate, personalized readings. Through detailed analysis of users' palm prints, it offers insights into their personality, potential life paths, and future possibilities. Designed to deliver in-depth, real-time interpretations, our app combines ancient palmistry with modern innovation, making it accessible, fun, and enlightening for users seeking self-discovery and guidance in a seamless, intuitive interface.",
        },
        {
            icon: '/images/apps/icon_3.webp',
            image: '/images/apps/image_3.webp',
            title: '4K Live Wallpapers',
            text: "Our 4K wallpapers app offers a visually stunning collection of high-quality images designed to elevate your device's look and feel. With a curated selection of vibrant, crisp, and diverse wallpapers, users can easily personalize their screens and enhance their digital experience. Whether you're into nature, architecture, or abstract art, our app provides a seamless and enjoyable way to transform your device with just a few taps, delivering fresh, striking visuals that suit every style and preference.",
        },
    ];
    return (
        <Box
            id="our_apps"
            sx={{
                scrollMarginTop: isMobile ? '30px' : undefined,
            }}
        >
            <Box
                sx={{
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: isMobile ? '80px 20px' : '120px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isMobile ? '40px' : '80px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '32px' : '56px',
                        fontFamily: 'Inter500',
                        lineHeight: 'normal',
                        maxWidth: '555px',
                    }}
                >
                    Our applications
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '40px' : '50px',
                    }}
                >
                    {points.map((el, i) => (
                        <Box
                            key={i}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: isMobile ? '10px' : '15px',
                            }}
                        >
                            <img width={72} src={el.img} alt="icon"></img>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontFamily: 'Inter500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {el.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Inter400',
                                    lineHeight: '24px',
                                    color: 'rgba(25, 25, 25, 0.70)',
                                }}
                            >
                                {el.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: isMobile ? '30px' : '40px',
                    }}
                >
                    {apps.map((app, i) => (
                        <Box
                            key={i}
                            sx={{
                                borderRadius: '32px',
                                background: '#F8F9FA',
                                padding: isMobile ? '20px' : '50px',
                                display: 'flex',
                                gap: isMobile ? '0px' : '50px',
                                flexDirection: isMobile ? 'column-reverse' : 'row',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {!isMobile && <img width={80} src={app.icon} alt={app.title}></img>}
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '24px' : '40px',
                                            fontFamily: 'Inter500',
                                            lineHeight: 'normal',
                                            color: '#191919',
                                            marginTop: isMobile ? '30px' : '40px',
                                        }}
                                    >
                                        {app.title}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontFamily: 'Inter400',
                                            lineHeight: '24px',
                                            color: 'rgba(25, 25, 25, 0.70)',
                                            marginTop: isMobile ? '10px' : '20px',
                                        }}
                                    >
                                        {app.text}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    img: {
                                        width: isMobile ? '100%' : '568px',
                                    },
                                }}
                            >
                                <img src={app.image} alt={app.title}></img>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default OurApps;
