import { Box, Button, Dialog, Typography, useMediaQuery } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import Footer from './Footer';

function Header({ mailToSupport }: { mailToSupport: () => void }) {
    const [visible, setVisible] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width: 900px)');

    useLayoutEffect(() => {
        function updateScroll() {
            setVisible(window.scrollY < 70);
        }
        window.addEventListener('scroll', updateScroll);
        updateScroll();
        return () => window.removeEventListener('resize', updateScroll);
    }, []);

    const navItems = [
        {
            title: 'About Us',
            anchor: 'about_us',
        },
        {
            title: 'Join Us',
            anchor: 'join_us',
        },
        {
            title: 'Cloud-Based Solution',
            anchor: 'cb_solution',
        },
        {
            title: 'Our Apps',
            anchor: 'our_apps',
        },
        {
            title: 'Contacts',
            anchor: 'footer_anchor',
        },
    ];

    return (
        <Box
            sx={{
                height: isMobile ? '86px' : undefined,
                position: 'fixed',
                zIndex: 99,
                top: !visible && !isMobile ? 20 : 0,
                background: visible ? 'transparent' : '#FFF',
                borderRadius: !visible && !isMobile ? '33px' : undefined,
                transition: 'all 0.5s ease',
                width: !visible && !isMobile ? '800px' : '100%',
                display: 'flex',
                alignItems: 'center',
                left: !visible && !isMobile ? '50%' : 0,
                transform: !visible && !isMobile ? 'translateX(-50%)' : undefined,
            }}
        >
            <Box
                sx={{
                    maxWidth: '1350px',
                    width: '100%',
                    margin: 'auto',
                    padding: isMobile ? '0 20px' : !visible ? '10px 20px' : '35px 20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <img
                    width={isMobile ? 92 : visible ? 118 : 59}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        const target = document.getElementById('header_anchor');
                        if (target) {
                            target.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}
                    src="/images/logo.svg"
                    alt="logo"
                ></img>
                {!isMobile && (
                    <Box
                        sx={{
                            display: 'flex',
                            gap: !visible && !isMobile ? '20px' : '40px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {navItems.map((item, index) => (
                            <Typography
                                key={index}
                                onClick={() => {
                                    const target = document.getElementById(item.anchor);
                                    if (target) {
                                        target.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}
                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Inter500',
                                    color: 'rgba(25, 25, 25, 0.70)',
                                    lineHeight: 'normal',
                                    transition: 'all 0.5s ease',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    textWrap: 'nowrap',
                                    '&:hover': {
                                        color: '#191919',
                                    },
                                }}
                            >
                                {item.title}
                            </Typography>
                        ))}
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Button
                        onClick={() => {
                            mailToSupport();
                        }}
                        variant="contained"
                        sx={{
                            backgroundColor: 'transparent',
                            borderRadius: '25px',
                            border: '1px solid rgba(25, 25, 25, 0.20)',
                            padding: '14px 24px',
                            fontSize: '16px',
                            fontFamily: 'Inter500',
                            color: '#191919',
                            textTransform: 'initial',
                            boxShadow: 'none',
                            lineHeight: 'normal',
                            textWrap: 'nowrap',
                            height: '46px',
                            '&:hover': {
                                boxShadow: 'none',
                                border: '1px solid rgba(25, 25, 25, 0.40)',
                            },
                        }}
                    >
                        Contact us
                    </Button>
                    {isMobile && (
                        <img
                            // width={isMobile ? 92 : 118}
                            // style={{ cursor: 'pointer' }}
                            onClick={() => {
                                setMenuOpen(true);
                            }}
                            src="/images/menu.svg"
                            alt="menu"
                        ></img>
                    )}
                </Box>
            </Box>
            <Dialog
                fullScreen
                open={isMenuOpen}
                // onClose={handleClose}
                // TransitionComponent={Transition}
            >
                <Box
                    sx={{
                        // border: '1px solid red',
                        background: '#191919',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <img
                        // width={isMobile ? 92 : 118}
                        style={{ cursor: 'pointer', position: 'absolute', top: 20, right: 20 }}
                        onClick={() => {
                            setMenuOpen(false);
                        }}
                        src="/images/close_menu.svg"
                        alt="close"
                    ></img>
                    <Box
                        sx={{
                            padding: '86px 20px 0',
                            // alignItems: 'center',
                            // justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                flexDirection: 'column',
                            }}
                        >
                            {navItems.map((item, index) => (
                                <Typography
                                    key={index}
                                    onClick={() => {
                                        setMenuOpen(false);
                                        const target = document.getElementById(item.anchor);
                                        if (target) {
                                            target.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    }}
                                    sx={{
                                        fontSize: '32px',
                                        fontFamily: 'Inter500',
                                        color: '#FFF',
                                        lineHeight: 'normal',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            ))}
                        </Box>
                        <Button
                            onClick={() => {
                                mailToSupport();
                            }}
                            sx={{
                                background: '#fff',
                                borderRadius: '32px',
                                height: '64px',
                                width: '100%',
                                color: '#191919',
                                textTransform: 'initial',
                                fontSize: '20px',
                                fontFamily: 'Inter500',
                                lineHeight: 'normal',
                                marginTop: '40px',
                            }}
                        >
                            Join Us
                            {!isMobile && <img src="/images/btn_arrow_black.svg" alt="arrow"></img>}
                        </Button>
                    </Box>
                    <Footer mailToSupport={mailToSupport} menuFooter />
                </Box>
            </Dialog>
        </Box>
    );
}

export default Header;
