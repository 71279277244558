import React, { useState } from 'react';
import { Box } from '@mui/material';
import Hero from './components/Hero';
import CloudBasedSolution from './components/CloudBasedSolution';
import Footer from './components/Footer';
import Header from './components/Header';
import PlayVideo from './components/PlayVideo';
import { APP_URLS } from './App.constants';
import AboutUs from './components/AboutUs';
import JoinUs from './components/JoinUs';
import OurApps from './components/OurApps';

function App() {
    const [isVideoOpened, setVideoOpened] = useState(false);

    const mailToSupport = () => {
        window.open(`mailto:${APP_URLS.SUPPORT}`, '_blank');
    };

    return (
        <Box>
            <Header mailToSupport={mailToSupport} />
            <Hero mailToSupport={mailToSupport} />
            <AboutUs />
            <JoinUs mailToSupport={mailToSupport} />
            <CloudBasedSolution setVideoOpened={setVideoOpened} />
            <OurApps />
            <Footer mailToSupport={mailToSupport} />

            <PlayVideo isOpened={isVideoOpened} setOpened={setVideoOpened} />
        </Box>
    );
}

export default App;
