import { Box, Typography, useMediaQuery } from '@mui/material';

function AboutUs() {
    const isMobile = useMediaQuery('(max-width:800px)');
    const points = [
        {
            img: '/images/icons/icon1.svg',
            title: 'Innovative Approach',
            text: 'We specialize in the development of web and mobile applications that function seamlessly across devices, standing out from traditional app store offerings.',
        },
        {
            img: '/images/icons/icon2.svg',
            title: 'Versatile Expertise',
            text: 'Our work spans both B2B and B2C domains, enabling us to deliver high-quality solutions tailored to diverse industries and audiences.',
        },
        {
            img: '/images/icons/icon3.svg',
            title: 'User-Centric Design',
            text: "At Apphitminchan, we prioritize creating intuitive, user-friendly interfaces that not only meet users' needs but also foster enjoyment and loyalty. Every project is crafted with precision, ensuring users love returning to our apps.",
        },
    ];
    return (
        <Box id="about_us">
            <Box
                sx={{
                    maxWidth: '1350px',
                    margin: 'auto',
                    padding: '120px 20px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: isMobile ? '40px' : '80px',
                }}
            >
                <Typography
                    sx={{
                        fontSize: isMobile ? '32px' : '56px',
                        fontFamily: 'Inter500',
                        lineHeight: 'normal',
                        maxWidth: '555px',
                    }}
                >
                    Pioneering Seamless App Development
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '40px' : '50px',
                    }}
                >
                    {points.map((el, i) => (
                        <Box
                            key={i}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: isMobile ? '10px' : '15px',
                            }}
                        >
                            <img width={72} src={el.img} alt="icon"></img>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    fontFamily: 'Inter500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {el.title}
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    fontFamily: 'Inter400',
                                    lineHeight: '24px',
                                    color: 'rgba(25, 25, 25, 0.70)',
                                }}
                            >
                                {el.text}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default AboutUs;
